<script setup lang="ts">

import {exchangeIcon, loadingIcon} from "~/utils/icon-utils";
import type {FlightSearchForm} from "~/types/flight/search/FlightSearchForm";
import {useAirportStore} from "~/stores/flight/airport/useAirport";
import {storeToRefs} from "pinia";

const props = defineProps<{
    state: FlightSearchForm
    hasRoute?: boolean
    noPath?: boolean
}>()

const airportStore = useAirportStore()
const { data: airports, loading: airportLoading, q } = storeToRefs(airportStore)
const { getAirports } = airportStore

const getAirportsList = async (query: string | FocusEvent) => {
    q.value = query instanceof FocusEvent ? '' : query
    await getAirports()
    return airports.value
}

</script>

<template>
    <div v-if="!noPath" class="w-full flex gap-4 col-span-5 relative">
        <u-button
            :color="hasRoute ? 'white': 'primary'"
            variant="ghost"
            size="xs"
            :icon="exchangeIcon()"
            square
            :ui="{base: `${hasRoute ? 'border-white' : 'border-primary-900'} absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white border rounded-full`}"
        />
        <u-form-field
            name="origin"
            :ui="{root: hasRoute ? 'w-full bg-transparent border border-white rounded-r-2xl rounded-l-lg' : 'w-full bg-transparent border border-primary-900 rounded-r-2xl rounded-l-lg', error: 'absolute text-xs'}"
        >
            <u-select-menu
                v-model="state.origin"
                :filter-fields="['name_en', 'name_fa', 'iata_code', 'city.name_en', 'city.name_fa']"
                :items="airports"
                :loading="airportLoading"
                :search-input="{placeholder: 'جستجوی مبدا'}"
                placeholder="مبدا"
                label-key="city.name_fa"
                highlight
                color="gray"
                size="xl"
                variant="none"
                trailing
                :leading-icon="loadingIcon()"
                by="iata_code"
                @focus="getAirportsList"
                @update:searchTerm="getAirportsList"
                class="w-full"
                :ui="{value: hasRoute ? 'text-white' : 'text-black'}"
            >
                <template #item="{ item }">
                    <u-icon :name="planeIcon()"/>
                    <div class="flex flex-col">
                        <span class="truncate">{{ item.city.name_fa }}</span>
                        <div class="flex gap-2">
                            <span class="text-xs text-gray-400 font-light">{{ item.name_fa }}</span>
                            <span class="text-xs text-gray-400 font-light">{{ item.iata_code }}</span>
                        </div>
                    </div>
                </template>
                <template #empty="{searchTerm}">
                    <div
                        v-if="airportLoading"
                        class="w-full flex flex-col gap-2"

                    >
                        <u-skeleton
                            v-for="i in 5"
                            :key="i"
                            class="h-7 w-full"
                        />
                    </div>
                    <span v-else class="text-xs text-red-500 tracking-tight"><q
                        class="text-red-600">{{ searchTerm }}</q> پیدا نشد</span>
                </template>
            </u-select-menu>
            <template #error="{error}">{{ error }}</template>
        </u-form-field>
        <u-form-field
            name="destination"
            :ui="{root: hasRoute ? 'w-full bg-transparent border border-white rounded-r-2xl rounded-l-lg' : 'w-full bg-transparent border border-primary-900 rounded-r-2xl rounded-l-lg', error: 'absolute text-xs'}"
        >
            <u-select-menu
                v-model="state.destination"
                :filter-fields="['name_en', 'name_fa', 'iata_code', 'city.name_en', 'city.name_fa']"
                :items="airports"
                :loading="airportLoading"
                :search-input="{placeholder: 'جستجوی مقصد'}"
                placeholder="مقصد"
                label-key="city.name_fa"
                highlight
                color="gray"
                size="xl"
                variant="none"
                trailing
                :leading-icon="loadingIcon()"
                by="iata_code"
                @focus="getAirportsList"
                @update:searchTerm="getAirportsList"
                class="w-full"
                :ui="{value: hasRoute ? 'text-white' : 'text-black'}"
            >
                <template #item="{ item }">
                    <u-icon :name="planeIcon()"/>
                    <div class="flex flex-col">
                        <span class="truncate">{{ item.city.name_fa }}</span>
                        <div class="flex gap-2">
                            <span class="text-xs text-gray-400 font-light">{{ item.name_fa }}</span>
                            <span class="text-xs text-gray-400 font-light">{{ item.iata_code }}</span>
                        </div>
                    </div>
                </template>
                <template #empty="{searchTerm}">
                    <div
                        v-if="airportLoading"
                        class="w-full flex flex-col gap-2"

                    >
                        <u-skeleton
                            v-for="i in 5"
                            :key="i"
                            class="h-7 w-full"
                        />
                    </div>
                    <span v-else class="text-xs text-red-500 tracking-tight"><q
                        class="text-red-600">{{ searchTerm }}</q> پیدا نشد</span>
                </template>
            </u-select-menu>
        </u-form-field>
    </div>
</template>
